import { connection } from '@/router/private/modules/thirdParty/platforms/connection';
import { cost } from './cost';
import { CONTEXTS } from '@/model/shared/contexts';
import { ROLES } from '@/model/shared/roles';

const basePath = CONTEXTS.THIRD_PARTY.baseUrl;

export const platforms = {
  path: 'platforms',
  name: `${basePath}-platform`,
  redirect: { name: connection.name },
  component: {
    render(c) {
      return c('router-view');
    },
  },
  meta: {
    requiresAuth: true,
    rolesAccepted: [ROLES.ADMIN.id, ROLES.CLIENT.id],
  },
  children: [connection, cost],
  props: true,
};
