var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("asterix-application-layout", {
    scopedSlots: _vm._u([
      {
        key: "sidebar",
        fn: function () {
          return [
            _c(
              "asterix-sidebar",
              {
                model: {
                  value: _vm.isSidebarOpen,
                  callback: function ($$v) {
                    _vm.isSidebarOpen = $$v
                  },
                  expression: "isSidebarOpen",
                },
              },
              [
                _c(
                  "sun-sidebar-group",
                  { staticClass: "mt-8" },
                  [
                    _c(
                      "sun-sidebar-nav-item",
                      {
                        attrs: { to: _vm.analytics },
                        scopedSlots: _vm._u([
                          {
                            key: "icon",
                            fn: function () {
                              return [
                                _c("analytics-svg", { staticClass: "w-4" }),
                              ]
                            },
                            proxy: true,
                          },
                          {
                            key: "children",
                            fn: function () {
                              return [
                                _c(
                                  "sun-sidebar-nav-item",
                                  { attrs: { to: _vm.economics } },
                                  [_vm._v(" Economics ")]
                                ),
                                _c(
                                  "sun-sidebar-nav-item",
                                  { attrs: { to: _vm.branding } },
                                  [_vm._v(" Branding Web ")]
                                ),
                                _c(
                                  "sun-sidebar-nav-item",
                                  { attrs: { to: _vm.ott } },
                                  [_vm._v(" OTT ")]
                                ),
                                _c(
                                  "sun-sidebar-nav-item",
                                  { attrs: { to: _vm.youtube } },
                                  [_vm._v(" Youtube ")]
                                ),
                                _c(
                                  "sun-sidebar-nav-item",
                                  { attrs: { to: _vm.performance } },
                                  [_vm._v(" Performance ")]
                                ),
                                _c(
                                  "sun-sidebar-nav-item",
                                  { attrs: { to: _vm.skin } },
                                  [_vm._v(" Skin + Ignite ")]
                                ),
                                _c(
                                  "sun-sidebar-nav-item",
                                  { attrs: { to: _vm.precio } },
                                  [_vm._v(" Precio ")]
                                ),
                                _c(
                                  "sun-sidebar-nav-item",
                                  { attrs: { to: _vm.content } },
                                  [_vm._v(" Content ")]
                                ),
                                _c(
                                  "sun-sidebar-nav-item",
                                  { attrs: { to: _vm.others } },
                                  [_vm._v(" Others ")]
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      },
                      [
                        _c("span", { staticClass: "font-bold" }, [
                          _vm._v("Analytics"),
                        ]),
                      ]
                    ),
                    _c(
                      "sun-sidebar-nav-item",
                      {
                        attrs: { to: _vm.campaignManagerList },
                        scopedSlots: _vm._u([
                          {
                            key: "icon",
                            fn: function () {
                              return [
                                _c("category-svg", { staticClass: "w-4" }),
                              ]
                            },
                            proxy: true,
                          },
                          {
                            key: "children",
                            fn: function () {
                              return [
                                _c(
                                  "sun-sidebar-nav-item",
                                  { attrs: { to: _vm.campaignManagerList } },
                                  [_vm._v(" List All ")]
                                ),
                                _c(
                                  "sun-sidebar-nav-item",
                                  { attrs: { to: _vm.campaignManagerToday } },
                                  [_vm._v(" Finish Today ")]
                                ),
                                _c(
                                  "sun-sidebar-nav-item",
                                  {
                                    attrs: { to: _vm.campaignManagerTomorrow },
                                  },
                                  [_vm._v(" Finish Tomorrow ")]
                                ),
                                _c(
                                  "sun-sidebar-nav-item",
                                  {
                                    attrs: { to: _vm.campaignManagerStarting },
                                  },
                                  [_vm._v(" Starting Today ")]
                                ),
                                _c(
                                  "sun-sidebar-nav-item",
                                  {
                                    attrs: {
                                      to: _vm.campaignManagerStartingYesterday,
                                    },
                                  },
                                  [_vm._v(" Starting Yesterday ")]
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      },
                      [
                        _c("span", { staticClass: "font-bold" }, [
                          _vm._v("Campaign Manager"),
                        ]),
                      ]
                    ),
                    _c(
                      "sun-sidebar-nav-item",
                      {
                        attrs: { to: _vm.connection },
                        scopedSlots: _vm._u([
                          {
                            key: "icon",
                            fn: function () {
                              return [
                                _c("platform-svg", { staticClass: "w-4" }),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      },
                      [
                        _c("span", { staticClass: "font-bold" }, [
                          _vm._v("Platforms"),
                        ]),
                      ]
                    ),
                    _c(
                      "sun-sidebar-nav-item",
                      {
                        attrs: { to: _vm.admin },
                        scopedSlots: _vm._u([
                          {
                            key: "icon",
                            fn: function () {
                              return [_c("admin-svg", { staticClass: "w-4" })]
                            },
                            proxy: true,
                          },
                          {
                            key: "children",
                            fn: function () {
                              return [
                                _c(
                                  "sun-sidebar-nav-item",
                                  { attrs: { to: _vm.users } },
                                  [_vm._v(" Users ")]
                                ),
                                _c(
                                  "sun-sidebar-nav-item",
                                  { attrs: { to: _vm.scheduledEmails } },
                                  [_vm._v(" Scheduled Emails ")]
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      },
                      [_vm._v(" Admin ")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "header",
        fn: function () {
          return [
            _c("asterix-topbar", {
              staticClass: "z-40",
              on: {
                change: function ($event) {
                  _vm.isSidebarOpen = !_vm.isSidebarOpen
                },
              },
            }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }